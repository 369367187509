<template>
	<div class="page-plant-order">
		<div class="el-content mb12">
			<div class="kd-pacel">检索参数</div>
			<a-space>
				<a-select v-model:value="search.land_id" @change="getSpec" style="width: 180px;">
				    <a-select-option :value="0">请选择土地进行查询</a-select-option>
					<a-select-option v-for="(item,index) in search.land" :key="index"
						:value="item.id">{{item.land_name}}
				    </a-select-option>
				</a-select>
				<a-select v-model:value="search.spec_id" style="width: 180px;">
				    <a-select-option :value="0">请选择地块进行查询</a-select-option>
					<a-select-option v-for="(item,index) in search.spec" :key="index"
						:value="item.id">{{item.land_num}}
				    </a-select-option>
				</a-select>
				<a-input-search
					 style="width: 280px;"
					v-model:value="search.order_number"
					placeholder="订单编号"
					enter-button
					@search="getPlantOrder(1,info.limit)"
				/>

				<a-button @click="show.addPlant = true"><i class="ri-add-line ri-btn"></i>添加种植</a-button>
			</a-space>
		</div>
		
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'订单编号',dataIndex:'orders',slots:{customRender:'orders'}},
				{title:'土地名称',dataIndex:'land',slots:{customRender:'land'}},
				{title:'订单价格',dataIndex:'price',slots:{customRender:'price'}},
				{title:'订单状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #orders="{record}">
					<div><span class="f12">ID：</span>{{record.id}}</div>
					<div><span class="f12">订单编号：</span>{{record.order_number}}</div>
					<div><span class="f12">下单时间：</span>{{record.create_time}}</div>
				</template>
				<template #land="{record}">
					<div><span class="f12">土地名称：</span>{{record.land.land_name}}</div>
					<div><span class="f12">土地编号：</span>{{record.spec.land_num}}</div>
					<div><span class="f12">土地订单：</span>
						<a-button type="link">{{record.land_order_id}}</a-button>
					</div>
				</template>
				<template #price="{record}">
					<div><span class="f12">订单总价：</span><span class="kdred">{{record.total_price}}</span></div>
					<div v-if="record.pay_time"><span class="f12">支付时间：</span>{{record.pay_time}}</div>
				</template>
				<template #status="{record}">
					<a-tag :color="$status.getPlantOrderStatusBg(record)">
						{{$status.getPlantOrderStatus(record)}}
					</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<router-link :to="{path:isShop == 0 ?'/land/plant_detail':'/many_store/land/plant_detail',query:{id:record.id}}">
							<a-button type="primary" size="small">详情</a-button>
						</router-link>
						<com-plant-status-add 
							btn-size="small"
							btn-type="primary"
							:ids="record.plants" 
							:land-id="record.land_id"
							@success="getPlantOrder(info.page,info.limit)"
						>
						</com-plant-status-add>
						<a-dropdown>
						    <a-button type="primary" size="small">状态操作<i class="ri-arrow-down-s-line"></i></a-button>
						    <template #overlay>
								<a-menu>
									<a-menu-item @click="changeAllPlantStatus(record,'sau')">播种</a-menu-item>
									<a-menu-item @click="changeAllPlantStatus(record,'xiao')">幼苗</a-menu-item>
									<a-menu-item @click="changeAllPlantStatus(record,'up')">生长</a-menu-item>
									<a-menu-item>
										<com-plant-output-set
											:ids="record.plants" 
											@success="getPlantOrder(info.page,info.limit)"
										>成熟</com-plant-output-set>
									</a-menu-item>
									<a-menu-item @click="changeAllPlantStatus(record,'complete')">完成</a-menu-item>
									<a-menu-item @click="changeAllPlantStatus(record,'withered')">枯萎</a-menu-item>
								</a-menu>
						    </template>
						</a-dropdown>
					</a-space>
				</template>
			
				<template #expandedRowRender="{record}">
					<com-plant-list :list="record.plants" :selection="false" @success="getPlantOrder(info.page,info.limit)"></com-plant-list>
				</template>
			</a-table>

			<div class="pager">
				<a-pagination 
				show-size-changer 
				:default-current="info.page" 
				:total="info.count" 
				@showSizeChange="(page,e)=>{ getPlantOrder(info.page, e); }"
				@change="e=>{getPlantOrder(e, info.limit);}"/>
			</div>
		</div>

		<a-modal title="添加种植" v-model:visible="show.addPlant" @ok="sureAddPlant" width="800px">
			<a-form :label-col="{span:6}" :wrapper-col="{span:16}">
				<a-row>
					<a-col :span="12">
						<a-form-item label="关联土地" required>
						<a-input 
							style="width:290px" 
							v-model:value="form.order_number" 
							aria-placeholder="请输入土地订单编号"
							@change="getCanPlantSeed"
						/>
					</a-form-item>
					</a-col>
					<a-col :span="12">
						<a-form-item label="种植时间" required>
							<a-date-picker v-model:value="form.plant_time" />
						</a-form-item>
					</a-col>
				</a-row>
				
				<a-form-item label="可选种子" :label-col="{span:3}">
					<a-checkbox-group v-model:value="form.checkSeed" @change="getSelectSeed">
						<a-checkbox v-for="(item,index) in form.seedList" :key="index" :value="item.id">
							{{item.send_name}}
						</a-checkbox>
					</a-checkbox-group>
				</a-form-item>

				<a-table class="mb12" size="small" v-if="form.seed.length>0" :pagination="false" :data-source="form.seed" row-key="id" :columns="[
                    {title:'ID',dataIndex:'id'},
                    {title:'种子封面',dataIndex:'cover',slots:{customRender:'cover'}},
                    {title:'种子名称',dataIndex:'send_name'},
                    {title:'种植面积',dataIndex:'num',slots:{customRender:'num'}},
                ]" >
                    <template #cover="{record}">
                        <a-image :src="record.cover" style="width:20px;height:20px"></a-image>
                    </template>
					<template #num="{record}">
                        <a-input v-model:value="record.num" :min="1" type="number" style="width:120px" addon-after="㎡"></a-input>
                    </template>
                </a-table>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import landModel from '@/api/land.js'
import comPlantStatusAdd from '@/components/layout/land/com-plant-status-add.vue'
import comPlantOutputSet from '@/components/layout/land/com-plant-output-set.vue'
import comPlantList from '@/components/layout/land/com-plant-list.vue'
import comSelectTableList from '@/components/miniapp/com-select-table-list.vue'
import { reactive, toRefs } from 'vue'
export default{
	components:{
		comPlantStatusAdd,
		comPlantOutputSet,
		comPlantList,
		comSelectTableList
	},
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			search:{
				land:[],
				land_id:0,
				spec_id:0,
				order_number:''
			},
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
				traceQrcode:""
			},
			show:{
				addPlant:false
			},
			form:{
				order_number:"",
				plant_time:"",
				seed:[],
				seedList:[],
				checkSeed:[],
			}
		})
		getPlantOrder(1,_d.info.limit)
		getLand()
		function getPlantOrder(page,limit){
			landModel.getPlantOrder(page,limit,_d.search,res=>{
				_d.info = { limit,...res}
			})
		}

		function getLand(){
			landModel.getLandList(1,999,null,res=>{
				_d.search.land= res.list;
			})
		}

		function getSpec(e){
			_d.search.spec_id = ''
			_d.search.land.forEach(item=>{
				if( item.id == e ){
					_d.search.spec = item.fre_spec
				}
			})
		}

		const changeAllPlantStatus = (data,op) => {
			let ids = []
			data.plants.forEach(item=>{
				ids.push(item.id)
			})
			landModel.setPlantStatus(op,ids,()=>{
				getPlantOrder(_d.info.page,_d.info.limit)
			})
		}

		function getSelectSeed(e){
			let seed = [] , arrId = [] , oldSeed = JSON.parse(JSON.stringify(_d.form.seed))
			e.forEach(item=>{
				let temp = _d.form.seedList.find(val=>val.id == item)
				seed.push({...temp,num:1})
			})

			//剔除不要的数据
			oldSeed.forEach((item,index)=>{
				e.includes(item.id) ? arrId.push(item.id) :_d.form.seed.splice(index,1)
			})

			//追加需要的数据
			seed.forEach(item=>{
				if( !arrId.includes(item.id)) _d.form.seed.push(item)
			})
		}

		const sureAddPlant = ()=>landModel.addLandPlantOrder(_d.form,()=>{
			_d.show.addPlant = false,
			_d.form = {
				order_number:"",
				seed:[],
				plant_time:"",
				checkSeed:[],
				seedList:[],
			}
		})

		const getCanPlantSeed = ()=>{
			if( !_d.form.order_number ) return
			landModel.getCanPlantSeed(1,999,{order_number:_d.form.order_number},res=>{
				_d.form.seedList = res.list
			})
		}

		return {
			...toRefs(_d),
			getPlantOrder,
			changeAllPlantStatus,
			getSpec,
			getLand,
			getSelectSeed,
			sureAddPlant,
			getCanPlantSeed
		}
	}
}
</script>
<style lang="scss" scoped>
.add-seed{
	width: 100%;
	height: 32px;
	line-height: 32px;
	text-align: center;
	border: 1px dashed #dadada;
	margin-top: 24px;
	cursor: pointer;

}
</style>
